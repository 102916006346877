import DisplayContainer from "../display-container";
import queryAll, { query } from "../dom-helpers/query";

type AnimationDefinition = {
  duration: number;
  fade: string;
  move: "none" | "up" | "down" | "left" | "right";
  rotate: number;
  zoom: number;
};
type AnimatedItem = {
  element: HTMLElement;
  media: HTMLElement;
  hasAnimated: boolean;
  animation: AnimationDefinition;
};

let items: AnimatedItem[];

export default function initForegroundMedia(): void {
  const scroller = window as Window;

  // Find our animated elements
  items = queryAll("[data-animation]")
    .map(element => {
      try {
        const animation = JSON.parse(atob(element.getAttribute("data-animation")));

        // Get the element that needs to load
        let media: HTMLElement = element.querySelector("video");
        if (!media) {
          media = element.querySelector("picture > img");
        }

        return animation.preset === "none"
          ? null
          : {
              element,
              media,
              hasAnimated: false,
              animation,
            };
      } catch (e) {
        return null;
      }
    })
    .filter(item => item);

  if (!isReducedMotion()) {
    // Set the initial values
    for (const item of items) {
      item.element.style.transformOrigin = "center";
      item.element.style.transform = "translate(0, 0) rotate(0) scale(1)";
      item.element.style.opacity = "0";
    }
  }

  scroller.addEventListener("scroll", onScroll, { passive: true });
  onScroll();
}

function onScroll(): void {
  const height = DisplayContainer.getHeight();
  const isStoryFinished: boolean = (query("article.Theme-Story") as Element).getBoundingClientRect().bottom - height < height * 0.1;

  const nextItems = items.filter(item => {
    if (item.hasAnimated) return false;

    const rect: DOMRect = item.element.getBoundingClientRect();
    const progress: number = rect.top / height;

    return isStoryFinished || progress <= 0.7;
  });

  for (let i = 0; i < nextItems.length; i++) {
    const item = nextItems[i];
    item.hasAnimated = true;

    const animation: AnimationDefinition = {
      duration: 500,
      fade: "none",
      move: "none",
      rotate: 0,
      zoom: 1,
      ...item.animation,
    };

    let opacity = [0, 1, 1, 1, 1];
    switch (animation.fade) {
      case "fast":
        opacity = [0, 1, 1, 1];
        break;
      case "slow":
        opacity = [0, 1];
        break;
    }

    const playAnimation: () => void = () => animate(item, animation, opacity, i * 200);

    if (item.media instanceof HTMLImageElement) {
      if (item.media.complete) {
        playAnimation();
      } else {
        item.media.addEventListener("load", playAnimation);
      }
    } else {
      playAnimation();
    }
  }
}

function animate(item: AnimatedItem, animation: AnimationDefinition, opacity: number[], delay: number): void {
  item.element.animate(
    {
      opacity,
      transform: [
        `translate(${getTranslate(animation.move)}) rotate(${animation.rotate ?? 0}deg) scale(${animation.zoom ?? 1})`,
        "translate(0, 0) rotate(0) scale(1)",
      ],
    },
    {
      delay,
      duration: isReducedMotion() ? 0 : animation.duration,
      easing: getEasing(animation),
      iterations: 1,
      fill: "forwards",
    }
  );
}

function getEasing(animation: AnimationDefinition): string {
  if (["up", "down"].includes(animation.move)) return "ease-in-out";
  if (animation.zoom > 1) return "ease-in";
  if (animation.rotate !== 0) return "ease-in";

  return "cubic-bezier(.27,0,.43,1.22)";
}

function getTranslate(move: "none" | "up" | "down" | "left" | "right"): string {
  switch (move) {
    case "up":
      return "0%,20%";
    case "down":
      return "0%,-20%";
    case "left":
      return "20%,0%";
    case "right":
      return "-20%,0%";
    default:
      return "0%,0%";
  }
}

function isReducedMotion(): boolean {
  return window["__prefersReducedMotion"] || window.matchMedia("(prefers-reduced-motion: reduce)")?.matches;
}
