export default function initPrintView(): void {
  const mediaQueryList = window.matchMedia("print");

  mediaQueryList.addEventListener("change", handlePrintChange);

  function handlePrintChange(e: MediaQueryListEvent): void {
    const carouselPrintContainers = document.querySelectorAll(".carousel-print-container");
    const regularCarouselContainers = document.querySelectorAll(".carousel-container");

    if (e.matches) {
      // Entering print mode: show all carousel print containers and hide regular ones
      carouselPrintContainers.forEach(container => container?.removeAttribute("style"));
      regularCarouselContainers.forEach(container => container?.setAttribute("style", "display: none;"));
    } else {
      // Exiting print mode: hide all carousel print containers and show regular ones
      carouselPrintContainers.forEach(container => container?.setAttribute("style", "display: none;"));
      regularCarouselContainers.forEach(container => container?.removeAttribute("style"));
    }
  }

  // Initial check for when the page first loads
  handlePrintChange({
    matches: mediaQueryList.matches,
  } as MediaQueryListEvent);
}
