import queryAll, { query } from "../dom-helpers/query";

export default function initBurgerNav(burgerColor?: string | undefined): void {
  const nav = query(".Navigation");
  const burger = query(".Navigation__hamburger");

  if (!nav || !burger) {
    return;
  }

  const navItems = queryAll(".Navigation__item", nav);

  // If there are no nav items, hide the burger
  // This is not the ideal place to do so, but the burger needs some heavy refactoring (STO-4867)
  if (navItems.length === 0) {
    burger.classList.add("hidden"); // hide the burger - we need to use class to override !important rules...
    return;
  }

  if (burgerColor) {
    addCustomBurgerColor(burgerColor);
  }
  listenToBurger(burger, nav);
  listenToNavItems(navItems, nav, burger);
  enableBurger(burger);
}

/**
 * This is currently only used for adding custom color to burgers in published Projects
 */
function addCustomBurgerColor(burgerColor: string): void {
  queryAll(".Navigation__hamburger span").forEach(span => (span.style.backgroundColor = burgerColor));
}

export function listenToBurger(burger: HTMLElement, nav: HTMLElement): void {
  /**
   * Event handler to simulate focus trap on the navigation links when the burger menu is open
   */
  function handleLastLinkTab(firstLink: HTMLAnchorElement) {
    return (e: KeyboardEvent) => {
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        firstLink.focus();
      }
    };
  }

  /**
   * Event handler to simulate focus trap on the navigation links when the burger menu is open
   */
  function handleFirstLinkTab(lastLink: HTMLAnchorElement) {
    return (e: KeyboardEvent) => {
      if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        lastLink.focus();
      }
    };
  }

  burger.addEventListener("click", (event: MouseEvent) => {
    event.preventDefault();
    nav.classList.toggle("Navigation--isActive");
    const isNowActive = nav.classList.contains("Navigation--isActive");
    const ariaExpandedValue = isNowActive ? "true" : "false";
    burger.setAttribute("aria-expanded", ariaExpandedValue);

    // Simulate focus trap/modal behavior for the hamburger menu
    const navLinks = queryAll("nav.Theme-NavigationBar a") as Array<HTMLAnchorElement>;
    const firstLink = navLinks.at(0);
    const lastLink = navLinks.at(-1);

    const firstLinkTabHandler = firstLink ? handleFirstLinkTab(lastLink) : null;
    const lastLinkTabHandler = lastLink ? handleLastLinkTab(firstLink) : null;

    function removeListenerOnBurgerClose(): void {
      nav.classList.remove("Navigation--isActive");
      burger.setAttribute("aria-expanded", "false");
      burger.focus();
      firstLink?.removeEventListener("keydown", firstLinkTabHandler);
      lastLink?.removeEventListener("keydown", lastLinkTabHandler);
    }

    function handleBurgerOutsideClick(event: MouseEvent): void {
      if (!(event.target as HTMLElement).closest(".Navigation__hamburger") && !nav.contains(event.target as Node)) {
        removeListenerOnBurgerClose();
      }
    }
    function handleBurgerEscapeKey(event: KeyboardEvent): void {
      if (event.key === "Escape") {
        removeListenerOnBurgerClose();
      }
    }

    if (isNowActive) {
      firstLink?.focus();
      firstLink?.addEventListener("keydown", firstLinkTabHandler);
      lastLink?.addEventListener("keydown", lastLinkTabHandler);
      document.addEventListener("click", handleBurgerOutsideClick);
      document.addEventListener("keydown", handleBurgerEscapeKey);
    } else {
      firstLink?.removeEventListener("keydown", firstLinkTabHandler);
      lastLink?.removeEventListener("keydown", lastLinkTabHandler);
      document.removeEventListener("click", handleBurgerOutsideClick);
      document.removeEventListener("keydown", handleBurgerEscapeKey);
      burger.focus();
    }
  });
}

export function enableBurger(burger: HTMLElement): void {
  burger.classList.remove("Navigation__hamburger--initializing");
}

export function listenToNavItems(navItems: HTMLElement[], nav: HTMLElement, burger: HTMLElement): void {
  const onNavItemClick = () => {
    nav.classList.remove("Navigation--isActive");
    burger.setAttribute("aria-expanded", "false");
  };
  navItems.forEach(i => i.addEventListener("click", onNavItemClick));
}
