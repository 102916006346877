import fastdom from "fastdom";

import queryAll, { query } from "../dom-helpers/query";
import { TConfig, getConfig } from "./config";
import { easeInOut, getScrollProgress } from "./ease";
import "./effects.scss";

let onScrollCallbacks: Array<() => void> = [];

function onScroll(): void {
  onScrollCallbacks.forEach(cb => cb());
}

export default function initBackgroundMedia(): void {
  const isEditor = !!query("#editor-viewport");

  if (isEditor) {
    function onFocusChange(): void {
      // Avoide size change when focusing from one element to the next within the same text block.
      setTimeout(onScroll, 100);
    }

    document.removeEventListener("focus", onFocusChange, true);
    document.addEventListener("focus", onFocusChange, true);
    document.removeEventListener("blur", onFocusChange, true);
    document.addEventListener("blur", onFocusChange, true);
  }

  const scroller: HTMLElement | Window = isEditor ? (document.querySelector("#editor-viewport") as HTMLElement) : (window as Window);

  scroller.removeEventListener("scroll", onScroll);
  scroller.addEventListener("scroll", onScroll, { passive: true });

  onScrollCallbacks = [];

  fastdom.mutate(() => {
    queryAll("[data-effects]").forEach(section => {
      const config: TConfig = getConfig(section);

      const mediaSelector = isEditor
        ? ".Theme-Layer-background-viewport:not(.Theme-Layer-background-viewport-cyclops)"
        : ".Theme-BackgroundMedia:not(.Theme-BackgroundModel)";
      const mediaParents: Array<HTMLElement> = queryAll(mediaSelector, section);
      const textParents: Array<HTMLElement> = queryAll(".Theme-Layer-TextBlock-Inner", section);

      if (!config.hasEffects) {
        // Reset effects if we are in the editor
        if (isEditor) onScroll();
        return;
      }

      mediaParents.forEach(parent => {
        let medias: Array<HTMLElement> = queryAll("video", parent);
        if (medias.length === 0) {
          medias = queryAll("img", parent).filter(img => img.className.indexOf("InstantImage__img") === -1);
        }

        // NOTE: When the background is not fixed the second copy goes below the other one
        if (config.needsBackgroundClone) {
          queryAll("picture", parent).forEach((picture, index) => {
            if (index === 0) return;
            picture.style.setProperty("position", "absolute");
            picture.style.setProperty("top", "0");
          });
        }

        medias.forEach((media, index) => {
          // Instant images add a 1s transition to opacity
          media.style.setProperty("transition", "none");

          if (media.tagName.toLowerCase() === "img" && index === 0) {
            applyBackgroundFilters(media, config, 0);
          }

          media.style.setProperty("transform", `scale(1)`);
          media.style.setProperty("opacity", "1");
        });
      });

      textParents.forEach(parent => {
        if (isWithinSplitSection(parent)) {
          parent.classList.remove("Layout__flex--xleft");
          parent.classList.remove("Layout__flex--xright");
          parent.classList.add("Layout__flex--xcenter");
        }
        // Section overflow visible ('add section' button)
        parent.parentElement.parentElement.style.setProperty("overflow", "visible");

        const isRightCentered = parent.className.indexOf("Layout__flex--xright") > -1;
        const isLeftCentered = parent.className.indexOf("Layout__flex--xleft") > -1;
        if (isLeftCentered) {
          parent.style.setProperty("transform-origin", "0% 0%");
        } else if (isRightCentered) {
          parent.style.setProperty("transform-origin", "100% 0%");
        } else {
          parent.style.removeProperty("transform-origin");
        }

        const children: Array<HTMLElement> = isEditor
          ? queryAll(".NodeContent")
          : [].slice.call(parent.firstElementChild.firstElementChild.children);
        const items: Array<HTMLElement> = config.textPerLine ? children : [parent];
        items.forEach((text: HTMLDivElement) => {
          const willChange: Array<string> = [];
          // Add a will-change for iOS text transforms
          if (config.textZoom !== "none" && navigator.maxTouchPoints > 0) {
            willChange.push("transform");
          }
          if (config.textBlur !== "none") {
            willChange.push("filter");
          }

          if (willChange.length > 0) {
            text.style.setProperty("will-change", willChange.join(", "));
          }
        });
      });

      function onScrollCallback(): void {
        const sectionPercent = getScrollProgress(section).percent;

        if (sectionPercent <= 0 || sectionPercent >= 1) return;

        mediaParents.forEach((parent: HTMLDivElement) => {
          let medias: Array<HTMLElement> = queryAll("video", parent);
          if (medias.length === 0) {
            medias = queryAll("img", parent).filter(img => img.className.indexOf("InstantImage__img") === -1);
          }

          const { percent, threshold } = getScrollProgress(parent, 0, 0.5);

          medias.forEach((media, index) => {
            // Clip zoomed images inside their picture frame
            media.parentElement.style.setProperty("overflow", "hidden");

            if (config.backgroundZoom === "in") {
              media.style.setProperty("transform", `scale(${1 + 0.4 * percent})`);
            } else if (config.backgroundZoom === "out") {
              media.style.setProperty("transform", `scale(${1.4 - 0.4 * percent})`);
            }

            let opacity: number = 1;

            if (config.backgroundFade !== "none") {
              if (
                (["in", "both"].includes(config.backgroundFade) && percent < 0.5) ||
                (["out", "both"].includes(config.backgroundFade) && percent > 0.5)
              ) {
                opacity = easeInOut(percent, threshold, 2);
              } else {
                opacity = 1;
              }
              media.style.setProperty("opacity", `${opacity}`);
            }

            if (media.tagName.toLowerCase() === "img") {
              if (index === 0) {
                applyBackgroundFilters(media, config, percent);
              } else {
                /**
                 * Blur and grayscale effects work by fading between two copies of the same media
                 */
                opacity *= easeInOut(percent, threshold);
                media.style.setProperty("opacity", `${opacity}`);
              }
            }
          });
        });

        textParents.forEach((parent: HTMLDivElement) => {
          const children: Array<HTMLElement> = isEditor
            ? queryAll(".NodeContent", parent)
            : [].slice.call(parent.firstElementChild.firstElementChild.children);

          // Make sure the other possible items have their properties reset
          if (isEditor) {
            // Element is focused so disable any effects (except zoom)
            const isFocused: boolean = parent.contains(document.activeElement) || Boolean(parent.querySelector("[data-state=open]"));
            _resetTextProperties(config.textPerLine, parent, children, !isFocused);

            if (isFocused) return;
          }

          const items: Array<HTMLElement> = config.textPerLine ? children : [parent];
          items.forEach((text: HTMLDivElement) => {
            const { percent, threshold } = getScrollProgress(text, 0, 0.2);

            // Apply zoom
            if (config.textZoom === "in") {
              text.style.setProperty("transform", `scale(${0.7 + 0.3 * percent})`);
            } else if (config.textZoom === "out") {
              text.style.setProperty("transform", `scale(${1 - 0.3 * percent})`);
            }

            // Apply blur
            const filter: Array<string> = [];
            if (
              (["in", "both"].includes(config.textBlur) && percent < 0.5) ||
              (["out", "both"].includes(config.textBlur) && percent > 0.5)
            ) {
              filter.push(`blur(${(20 * (1 - easeInOut(percent, threshold, 2))).toFixed(2)}px)`);
            }
            const filterValue: string = filter.join(" ");
            if (text.style.getPropertyValue("filter") !== filterValue) {
              text.style.setProperty("filter", filterValue);
            }

            // Apply fade
            let opacity: number = 1;
            if (
              (["in", "both"].includes(config.textFade) && percent < 0.5) ||
              (["out", "both"].includes(config.textFade) && percent > 0.5)
            ) {
              opacity *= easeInOut(percent, threshold);
            } else {
              opacity = 1;
            }
            text.style.setProperty("opacity", `${opacity}`);
          });
        });
      }

      onScrollCallbacks.push(onScrollCallback);
    });
  });

  setTimeout(onScroll, 500);
}

function applyBackgroundFilters(media: HTMLElement, config: TConfig, scrollProgress: number): void {
  const filter: Array<string> = [];

  if (scrollProgress < 0.5) {
    if (["in", "both"].includes(config.backgroundBlur)) filter.push("blur(20px)");
    if (["in", "both"].includes(config.backgroundGrayscale)) filter.push("grayscale(100%)");
  } else {
    if (["out", "both"].includes(config.backgroundBlur)) filter.push("blur(20px)");
    if (["out", "both"].includes(config.backgroundGrayscale)) filter.push("grayscale(100%)");
  }

  const filterValue: string = filter.join(" ");

  if (media.style.getPropertyValue("filter") !== filterValue) {
    media.style.setProperty("filter", filterValue);
  }
}

/*
 * Recursively walk up the DOM tree to find the `Theme-Section` to determine if it is a split section
 * Limitations: this won't work if the parent doesn't have the class or if the split layout change classnames (SplitLayou)
 */
function isWithinSplitSection(child: HTMLElement): boolean {
  let element = child;

  while (element && !element.classList.contains("Theme-Section")) {
    if (element.parentNode) {
      element = element.parentNode as HTMLElement;
    } else {
      console.error("Could not find the parent Theme-section");
    }
  }

  return element.classList.contains("SplitLayout");
}

function _resetTextProperties(isTextPerLine: boolean, parent: HTMLElement, children: HTMLElement[], resetZoom: boolean = true): void {
  if (isTextPerLine || parent.contains(document.activeElement)) {
    if (resetZoom) parent.style.removeProperty("transform");
    parent.style.removeProperty("filter");
    parent.style.removeProperty("opacity");
  }
  if (!isTextPerLine || parent.contains(document.activeElement)) {
    children.forEach((text: HTMLDivElement) => {
      if (resetZoom) text.style.removeProperty("transform");
      text.style.removeProperty("filter");
      text.style.removeProperty("opacity");
    });
  }
}
