import "core-js/actual/array/flat-map";
import "core-js/actual/array/includes";
import "core-js/actual/string/includes";
import "core-js/actual/string/pad-start";
import "element-closest";
import "whatwg-fetch";

import initAnalytics from "./analytics";
import initBackgroundMedia from "./background-media";
import initBackgroundScrollmations from "./background-scrollmation";
import "./background-transition/background-transition";
import initBackgroundVideos from "./background-videos";
import "./caption/index.scss";
import initCardCanvas from "./card-canvas";
import "./core";
import initDeviceDetect from "./device-detect";
import initDisplay from "./display";
import { initDisplayContainer } from "./display-container";
import initOnVisibilityChange from "./dom-helpers/on-visibility-change";
import "./embed/embed.scss";
import initFocalPoint from "./focal-point/index";
import initFootnotes from "./footnote/index";
import initForegroundMedia from "./foreground-media";
import "./full-size/full-size.scss";
import "./grid-section/index.scss";
import "./header/header.scss";
import initHideOffscreen from "./hide-offscreen";
import initInlineMedia from "./inline-media";
import "./inline-table/index.scss";
import "./layer/layer.scss";
import initLayout from "./layout";
import initLazyloading from "./lazyload";
import "./levels/levels.scss";
import { initLoop } from "./loop";
import initMediaGalleries from "./media-gallery";

/**
 * IE & Edge:
 * Override smooth scroll to be linear with integer steps
 */
import overrideSmoothScroll from "./ms-smooth-scroll";
import initNavItems from "./navigation";
import "./normalize/index.scss";
import initObjectFit from "./object-fit";
import "./overflow/overflow.scss";
import initParentWidth from "./parent-width";
import "./position-sticky/sticky.scss";
import initPrintView from "./print/print-view";
import "./reading-progress-bar/index";
import "./related-stories/index.scss";
import "./responsive/responsive.scss";
import initScrollymoly from "./scrollymoly";
import "./section-error/section-error.scss";
import initSocialShareButtons from "./social-share";
import "./social-share/index.scss";
import initSoundCloudWidgets from "./soundcloud";
import initSplitLayout from "./split-layout/index";
import initStackedContent from "./stacked-content";
import initUnsplash from "./stock-media/unsplash";
import initGalleryRenderers from "./story-gallery-renderer/init-sections";
import initTextSize from "./text-size";
import initTransitionIn from "./transition-in";
import initVideoPlayers from "./videoplayer";

// INIT MODULES
initDisplay();
initDeviceDetect();
initDisplayContainer();
initLayout();
initLazyloading();
initObjectFit();
initStackedContent();
initLoop();
initOnVisibilityChange();
initTextSize();
initFocalPoint();
initTransitionIn();
initHideOffscreen();

initNavItems();
initInlineMedia();
initSoundCloudWidgets();
initParentWidth();
initPrintView();
initVideoPlayers({ body: document.body });
initBackgroundVideos();
initSocialShareButtons();
initAnalytics();
initGalleryRenderers();
initCardCanvas();
initBackgroundScrollmations();
initScrollymoly();
initSplitLayout();
initMediaGalleries();
initFootnotes();
initBackgroundMedia();
initForegroundMedia();

overrideSmoothScroll();
initUnsplash();
