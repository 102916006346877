import { registerWebComponent } from "../utils/web-components";

/**
 * Render a progress bar at the bottom of the page that tracks the current scroll position.
 * The color as well as the element scroll container can be configured using the following attributes:
 * - background-color
 *    The background color of the progress bar
 *    If it starts with #, it is interpreted as a hex color, otherwise, as a CSS variable
 * - foreground-color
 *    The foreground color of the progress bar
 *    If it starts with #, it is interpreted as a hex color, otherwise, as a CSS variable
 * - container-selector
 *    The selector of the element to track the scroll position
 *    Must be a valid CSS selector
 *    Defaults to the window
 *
 *   To use in React, simply import this file and use it as a custom element
 *
 *    @example
 *      import "@shorthand/story/reading-progress-bar";
 *
 *      // Then in your component
 *      <sh-reading-progress-bar container-selector="#content"></sh-reading-progress-bar>
 *
 *  By default, the colours will be based on the theme.
 *  You can override them by setting the CSS variables `--sh-reading-progress-bar-background` and `--sh-reading-progress-bar-foreground`.
 *
 *    @example
 *      // style.css
 *       sh-reading-progress-bar::part(background) {
 *        background-color: #bada55;
 *      }
 *
 *  You can also style it using `::parts`:
 *
 *    @example
 *      sh-reading-progress-bar::part(foreground) {
 *         background-color: #c0ffee;
 *       sh-reading-progress-bar {
 *        --sh-reading-progress-bar-background: #bada55;
 *        --sh-reading-progress-bar-foreground: #c0ffee;
 *      }
 */
export class ReadingProgressBar extends HTMLElement {
  private scrollContainer: HTMLElement | Window = window;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.innerHTML = `
      <style>
        .Reading-Progress-Bar-Background {
          display: block;
          width: 100vw;
          overflow: hidden;
          position: sticky;
          top: 0;
          right: 0;
          height: 0.25rem;
          background: var(--sh-reading-progress-bar-background, white);
        }
        .Reading-Progress-Bar-Foreground {
          height: 100%;
          width: 100%;
          transform-origin: left;
          transform: scaleX(0);
          transition: transform 0.1s linear;
          transition: transform 0.1s linear;
          border-radius: 0 .25rem .25rem 0;
          background: var(--sh-reading-progress-bar-foreground, black);
        }
      </style>
      <div class="Reading-Progress-Bar-Background" part="background">
        <div class="Reading-Progress-Bar-Foreground" part="foreground"></div>
      </div>
    `;
  }

  connectedCallback() {
    const containerSelector = this.getAttribute("container-selector");
    if (containerSelector) {
      this.scrollContainer = (document.querySelector(containerSelector) as HTMLElement) || (window as Window);
    }
    this.scrollContainer.addEventListener("scroll", this.updateProgressBar, { passive: true });
  }

  disconnectedCallback() {
    this.scrollContainer.removeEventListener("scroll", this.updateProgressBar);
  }

  /**
   * Handle the progress bar width.
   * Not that we finish at 102% as a magic number to go a bit further than the background to handle the border radius
   */
  private updateProgressBar = () => {
    window.requestAnimationFrame(() => {
      const scrollElement = this.scrollContainer instanceof Window ? document.documentElement : this.scrollContainer;
      const scrollPosition = this.scrollContainer instanceof Window ? window.scrollY : scrollElement.scrollTop;
      const scrollHeight = scrollElement.scrollHeight;
      const clientHeight = scrollElement.clientHeight;

      const progress = (scrollPosition / (scrollHeight - clientHeight)) * 100;
      const progressBar = this.shadowRoot.querySelector(".Reading-Progress-Bar-Foreground") as HTMLDivElement;

      progressBar.style.transform = `scaleX(${progress / 100})`;
    });
  };
}

registerWebComponent("sh-reading-progress-bar", ReadingProgressBar);
